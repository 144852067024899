import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/404"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <div id="wrapper">
		 <div id="main">
			<div class="inner">
        <StaticImage
        src="../images/404.png"
        width={182}
        quality={100}
        formats={["auto", "webp", "avif"]}
        alt="Ошибка 404"
      />
    <h1 id="text04">Ошибка 404</h1>
    <h2 id="text03">Неправильно набран адрес или такой страницы на сайте больше не существует.</h2>
    <ul id="buttons06" class="buttons">
    <li><Link to="/" class="button n01">Главная страница</Link></li>
		</ul>
    </div>
		</div>
    </div>
  </Layout>
)

export const Head = () => (
  <Seo title="Ошибка 404"
       description="VipDosug: Ошибка 404">
       <meta name="robots" content="noindex,nofollow"/>
  </Seo>
)
export default NotFoundPage
